import React from 'react';
import { Layout, CartContent } from 'components';

export default function CartPage() {
  return (
    <Layout cart={true}>
      <CartContent />
    </Layout>
  );
}
